<template>
  <div class="row">
    <div class="col-md-8">
      <card title="Customer Installation request">
        <!--<h4 class="title">Customer Installation Requests</h4>-->
        <p class="category">
          This is a list of all the customer onboarding requests from the POS
          systems providers. Once the process of onboarding the customer is
          finished, please click on the Approve button to finalize the
          installation request with the POS system provider.
        </p>
      </card>
    </div>

    <div class="col-md-8">
      <card title="Customer Requests" height="100%">
        <el-collapse
          v-for="(request, id) in OnboardingRequests"
          v-bind:key="id"
        >
          <el-collapse-item
            :title="request.restaurant.name + ' ' + 'Installation Request'"
            :name="request.restaurant.name"
          >
            <p></p>
            <p>POS System: Shift4</p>
            <p>Request merchantID: {{ request.location.merchantId }}</p>
            <p>Request LocationID: {{ request.location.id }}</p>
            <p>Request Status: {{ request.state }}</p>
            <p>Request Date: {{ request.createdAt }}</p>
            <p>
              Address {{ request.restaurant.addressLine1 }}
              {{ request.restaurant.addressLine2 }}
            </p>
            <card title="Contact:">
              <p>First name: {{ request.contact.firstName }}</p>
              <p>Last name: {{ request.contact.lastName }}</p>
              <p>Email: {{ request.contact.email }}</p>
              <p>Phone: {{ request.contact.phone }}</p>
            </card>

            <div class="col-md-8 offset-md-10">
              <button
                type="button"
                class="btn btn-success"
                v-on:click="approveInstallationRequest(request, id)"
              >
                Approve</button
              >&nbsp;
            </div>
          </el-collapse-item>
        </el-collapse>
      </card>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { Collapse, CollapseItem } from "element-ui";
import Card from "../../../../components/Cards/Card.vue";
import { initAPI } from "src/gateway/api-instance.js";

export default {
  components: {
    Card,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem
  },
  computed: {},
  data() {
    return {
      OnboardingRequests: []
    };
  },
  computed: {
    // This is a computed variable, that it's value is recomputed every time it's used throughout the component.
    Instance() {
      return initAPI(this.$cookies.get("Token"), process.env.VUE_APP_API_URL);
    }
  },
  methods: {
    /*
      - Called when the user, clicks the "Approve" button in the intallation request card in the UI.
      - Calls the installtion request close API in the backend to invoke the shift4 close webhook.
      - Remove the installtion request from the card queue in the UI when the closing process is successful.
    */
    approveInstallationRequest(request, id) {
      request.APPROVED = true;
      this.Instance.post(
        "/webhooks/shift4/requests/installations/close",
        request
      )
        .then(function(response) {
          console.log(response.data);
          this.OnboardingRequests.splice(id, 1);
        })
        .catch(function(error) {
          console.error(error);
        });
    },
    /*
      - Calls a GET API to get a list of open installation requests from the shift4 marketplace in the database.
    */
    getInstallationRequests() {
      this.Instance.get("/webhooks/shift4/requests/installations").then(
        response => {
          this.OnboardingRequests = response.data;
        }
      );
    },
    /*
      - Calls a GET API to get the merchant id of a specific location ID in the database.
      - Was use for temporary testing and was kept for the posibilty of needing it in the future.
    */
    getMerchantId(locationId) {
      return this.Instance.get(
        "/webhooks/testing/merchantIds?locationId=" + locationId
      ).then(response => {
        return response;
      });
    }
  },
  mounted() {
    this.getInstallationRequests();
  }
};
</script>
<style>
.accordion-item {
  margin-bottom: 1rem;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
}

.accordion-header {
  margin: 0;
  padding: 1rem;
  line-height: 0;
}

.accordion-header button {
  width: 100%;
  padding: 0;
  border: 0;
  outline: 0;
  background-color: transparent;
  font-size: 16px;
  text-align: left;
  cursor: pointer;
}

.accordion-panel {
  display: none;
}

.accordion-panel.expanded {
  display: block;
  padding: 1rem;
  border-top: 1px solid #eee;
}
</style>
